import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import { TitleArea } from '../components/TemplateHelpers/1-TitleArea';
import { ContentArea } from '../components/TemplateHelpers/3-ContentArea';
import { EventPostQuery } from './EventPost';

export type EventCollectionQuery = {
  contentfulPageEventCollection: {
    title: string;
    subtitle?: string;
    slug: string;
  };
  allContentfulPageEventPost: {
    nodes: EventPostQuery['contentfulPageEventPost'][];
  };
};

const EventCollection = ({
  data,
}: PageProps<EventCollectionQuery>): ReactElement => {
  const { contentfulPageEventCollection, allContentfulPageEventPost } = data;
  const { title, subtitle, slug } = contentfulPageEventCollection;

  return (
    <>
      <TitleArea
        variant={'eventCollection'}
        title={title}
        subtitle={subtitle}
      />
      <ContentArea
        variant={'eventCollection'}
        eventPosts={allContentfulPageEventPost.nodes}
        parentSlug={slug}
      />
    </>
  );
};

export default EventCollection;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    contentfulPageEventCollection(id: { eq: $id }) {
      ...EventCollection
    }
    allContentfulPageEventPost(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: start, order: DESC }
    ) {
      nodes {
        ... on ContentfulPageEventPost {
          ...EventPostLink
        }
      }
    }
  }
`;
